@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	.title {
		
		position:relative;
		float:left;
		width:100%;

		@include sm {
			margin-bottom:10px;
		}

		.line {
			
		}

		&.left {
			
			.line {
				float:left;
				width:2vw;
				height:0.3vw;
				background-color:#BE0000;
				margin-top:1.3vw;
				transform:translate(0,-50%);

				@include sm {
					margin-top:10px;
					width:20px;
					height:3px;
				}
			}

			h2 {
			   float:left;
			   font-family:'Mulish';
			   font-size:2.0vw;
			   padding:0;
			   margin:0;
			   margin-bottom:0.5vw;
			   margin-left:2vw;
			   font-weight:normal;
			   color:#000;
			   font-weight:bold;

			   	@include md {
					font-size:16px;
					line-height:20px;
				}

			}

		}
		&.right {

			.line-new {
				display:inline-block;
				width:30px;
				height:5px;
				margin-left:1vw;
				background-color:#BE0000;
				margin-top:1.3vw;
				transform:translate(0,-50%);

				@include sm {
					margin-top:10px;
					width:20px;
					height:3px;
				}

			}
			
			.line {
				float:left;
				width:30px;
				height:5px;
				margin-left:2vw;
				background-color:#BE0000;
				margin-top:1.6vw;
				transform:translate(0,-50%);

				@include sm {
					margin-top:10px;
					width:20px;
					height:3px;
				}

			}

			h2 {
			   float:left;
			   font-family:'Mulish';
			   font-size:2.3vw;
			   padding:0;
			   margin:0;
			   margin-bottom:0.5vw;
			   font-weight:normal;
			   color:#000;
			   font-weight:bold;

			   	@include md {
					font-size:16px;
					line-height:20px;
				}

			}

		}

	}

	p,ul li,.p {
		padding:0;
		margin:0;
		font-size:1.2vw;
		line-height:2vw;
		color:#000;

		@include md {
			font-size:4.5vw;
			line-height:6.4vw;
		}

		@include md {
			font-size:14px;
			line-height:22px;
		}

		a {
			color:#888;

			&:hover {
				color:#000;
			}
			
		}

	}

	h1 {
	   font-family:'Museo';
	   font-size:7vw;
	   line-height:7.5vw;
	   padding:0;
	   margin:0;
	   font-weight:normal;
	   color:#060d36;

	   background: -webkit-linear-gradient(#060d36, #9d430d);
	  -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;

	   	@include md {
			font-size:50px;
			line-height:55px;
			margin-top:20px;
		}

	}

	h2 {
	   font-family:'Mulish';
	   font-size:2.3vw;
	   padding:0;
	   margin:0;
	   margin-top:1vw;
	   margin-bottom:0.5vw;
	   font-weight:normal;
	   color:#000;
	   font-weight:bold;

	   	@include md {
			font-size:16px;
			line-height:20px;
		}

	}

	h3 {
	   font-family:'Mulish';
	   font-size:1.5vw;
	   padding:0;
	   margin:0;
	   margin-top:1vw;
	   margin-bottom:0.5vw;
	   font-weight:normal;
	   color:#000;
	   font-weight:bold;

	   	@include md {
			font-size:16px;
			line-height:20px;
		}

	}

}

.textblock-2 {

	float:left;
	width:100%;

	p,ul li,.p {
		padding:0;
		margin:0;
		font-size:1.2vw;
		line-height:2.0vw;
		color:#000;

		a {
			color:#888;

			&:hover {
				color:#000;
			}
			
		}

		@include md {
			font-size:4.5vw;
			line-height:8vw;
		}

	}
	
	h1 {
	   font-family:'Suez One';
	   font-size:3vw;
	   padding:0;
	   margin:0;
	   margin-top:1vw;
	   margin-bottom:0.5vw;
	   font-weight:normal;
	   color:#060d36;

	   	@include md {
			font-size:16px;
			line-height:20px;
		}

	}

	h2 {
	   font-family:'Suez One';
	   font-size:1.5vw;
	   padding:0;
	   margin:0;
	   margin-top:1vw;
	   margin-bottom:0.5vw;
	   font-weight:normal;
	   color:#000;

	   	@include md {
			font-size:16px;
			line-height:20px;
		}
	}

}