@import "_default.scss_include-mixins";

html {
	overflow-x:hidden;
}
body {
	font-family:'Mulish', serif;
	background-color:#FFEBDB;
	overflow-x:hidden;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}

/*
Link
*/
a.bottomline {

	text-decoration:none;
	//width: calc(100%);
	background-image: linear-gradient(transparent calc(100% - 2px), white 2px);
	background-repeat: no-repeat;
	background-size: 0% 100%;
	transition: background-size 0.5s;

	&.color-black {
		background-image: linear-gradient(transparent calc(100% - 2px), black 2px);
	}
	&.color-orange {
		background-image: linear-gradient(transparent calc(100% - 2px), orange 2px);
	}
	
	&:hover {
	    background-size: 100% 100%;
	}

}

/*
Smooth parallax
*/
.js-fade-inonscroll {
	opacity:0; 
	margin-top:200px;

	@include sm {
		margin-top:100px;
	}

}

.page {
	padding-bottom:100px;
}

.menu {
	position:absolute;
	right:0;
	top:23vw;
	background-color:#041353;
	padding:10px;
	padding-left:20px;
	width:35%;
	border-radius:20px 0 0 20px;

	@include sm {
		top:-40px;
		width:250px;
	}

	&.sticky {
		position:fixed;
		top:0;
		z-index:10;
	}

	a {
		float:left;
		font-size:2vw;
		color:#fff;
		text-decoration:none;

		@include sm {
			font-size:13px;
		}

		&.active {
			background-size: 100% 100%;
		}
	}

	span {
		float:left;
		font-size:2vw;
		margin-top:-0.1vw;
		color:#fff;
		margin-left:10px;
		margin-right:10px;
		opacity:0.5;

		@include sm {
			font-size:13px;
		}

	}

}