@import "_default.scss_include-mixins";	 

header {

	h2 {

		float:left;
		margin-top:2.4vw;
		font-family:'Museo';
		color:#060d36;
		text-transform:uppercase;
		letter-spacing:0.2vw;

		span {
			color:#9d430d;
		}

		@include sm {
			margin-top:20px;
			font-size:17px;
		}

	}

}