@import "_default.scss_include-mixins";

footer {

	float:left;
	position:relative;
	width:100%;
	padding-top:120px;
	padding-bottom:120px;
	background-color:#fff;

	@include sm {
		padding-top:40px;
		padding-bottom:40px;
	}

	.block {
		position:absolute;
		width:100%;
		height:7vw;
		background-color:#ffebdb;
		top:-5vw;
		left:10vw;
		transform:rotate(-2deg);
	}

	.mt-40 {
		@include sm {
			margin-top:30px;
		}
	}

	h2 {
		font-size:3vw !important;
		line-height:4vw !important;
		font-weight:normal !important;

		@include sm {
			font-size:20px !important;
			line-height:30px !important;
		}

	}

	a {
		display:inline-block;
		margin-left:20px;
		margin-right:20px;
		padding:10px;
		padding-left:50px;
		padding-right:50px;
		font-size:1.5vw;
		color:#000;
		text-decoration:none;
		background-color:#fff;
		border-radius:50px;
		border:1px solid #ccc;

		@include sm {
			padding-left:20px;
			padding-right:20px;
			font-size:17px;
			margin-bottom:10px;
		}

		&:hover {
			color:#fff;
			background-color:#041353;
			border:1px solid #041353;
		}
	}

}