@import "_default.scss_include-mixins";

.button {
	display:inline-block;
	padding:15px;
	padding-left:4vw;
	padding-right:4vw;
	font-size:1.5vw;
	color:#000000;
	border:1px solid #707070;
	text-decoration: none;

	@include md {
		float:left;
		width:100%;
		margin-bottom:20px;
		padding-left:0;
		padding-right:0;
		font-size:6vw;
		text-align:center;
	}

	&:hover {
		background-color:#000;
		color:#fff;
	}

	.button-1 {

		label {
			font-weight:bold;
		}
		
	}

}