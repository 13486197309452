@import "_default.scss_include-mixins";

.page.page--home {

	.hero {
		position:relative;
		margin-top:40px;

		.background-image {
			position:absolute;
			top:-20vw;
			right:-12vw;
			width:55vw;
			height:50vw;
			background-image:url('../img/static/spray.png');
			background-repeat:no-repeat;
			background-size:contain;
			background-position:center;
		}
	}

	.block-padding-top { 
		margin-top:120px;

		@include sm {
			margin-top:40px;
		}
	}

	.rest {
		position:relative;
		margin-top:40px;

		.background-image {
			position:absolute;
			top:-10vw;
			left:-25vw;
			width:55vw;
			height:50vw;
			background-image:url('../img/static/spray.png');
			background-repeat:no-repeat;
			background-size:contain;
			background-position:center;
		}

		.persons {
			float:left;
			width:100%;

			@include sm {
				margin-top:20px;
			}

			.person {
				width:48%;

				font-size:1vw;
				line-height:1vw;

				@include sm {
					font-size:13px;
					line-height:13px;
				}

				&.left { 
					float:left;
				}
				&.right { 
					float:right;
				}

				.image {
					float:left;
					width:100%;
					padding-top:100%;
					border-radius:50%;
					background-color:#fff;
					border:1px solid #ccc;

					&.jonathan {
						background-image:url('https://finally.nl/img/static/jonathan.jpg');
						background-size:cover;
						background-repeat:no-repeat;
					}
					&.aleks {
						background-image:url('https://finally.nl/img/static/aleks.jpg');
						background-size:cover;
						background-repeat:no-repeat;
					}
				}

				.name {
					float:left;
					width:100%;
					margin-top:20px;
					text-align:center;
					font-weight:bold;
					color:#000;
				}

				.job {
					float:left;
					width:100%;
					margin-top:10px;
					text-align:center;
					color:#000;
				}

			}
		}

	}

	.rest2 {
		position:relative;
		margin-top:40px;

		.background-image {
			position:absolute;
			top:-20vw;
			right:-25vw;
			width:55vw;
			height:50vw;
			background-image:url('../img/static/spray.png');
			background-repeat:no-repeat;
			background-size:contain;
			background-position:center;
			z-index:2;

			@include sm {
				z-index:-5;
			}
		}

	}

}